export const API_URL_RESEND_EMAIL = '/api/v2/consultants/auths/register/resend'
export const API_URL_VERIFY_REGISTER = 'api/v2/consultants/auths/register/verify'
export const API_URL_LOGIN = '/api/v2/consultants/auths/login'
export const API_URL_REGISTER = '/api/v2/consultants/auths/register'
export const API_URL_CANDIDATE_REGISTER = '/api/v2/consultants/auths/register/candidate'
export const API_URL_FETCH_ME = '/api/v2/auths/me'
export const API_URL_RESET_PASSWORD = 'api/v2/consultants/auths/forgot-password'
export const API_URL_ME = '/api/v2/auths/me'

export const API_URL_FORGOT_PW_SEND_EMAIL = 'api/v2/consultants/auths/forgot-password/send'
export const API_URL_FORGOT_PW_VERIFY_TOKEN = 'api/v2/consultants/auths/forgot-password/verify'

export const API_URL_UPDATE_PASSWORD = '/api/v2/consultants/accounts/reset-password'

export const API_URL_SKILLS = '/api/v2/public/skills'
export const API_URL_JOB_CATEGORIES = '/api/v2/public/job_categories'
export const API_URL_LOCATION = '/api/v2/public/locations'
export const API_URL_GET_JOB_DETAIL = '/api/v2/consultants/jobs'
export const API_URL_GET_JOB_CONSULTANTS_DETAIL = '/api/v2/consultants/jobs'
export const API_URL_GET_PROFILE_COMPANY = '/api/v2/consultants/current_company'

export const API_URL_LIST_TEAM_MEMBER = `api/v2/consultants/members`

export const API_URL_INVITE_TEAM_MEMBER = '/api/v2/consultants/members/invites'
export const API_URL_RESEND_INVITE_MEMBER_EMAIL = '/api/v2/consultants/members/invites/resend'
export const API_URL_VERIFY_MEMBER_INVITE = '/api/v2/consultants/members/invites/verify'
export const API_URL_UPDATE_MEMBER_LOGIN = '/api/v2/consultants/members/invites/update'
export const API_URL_CANDIDATES_JOB = '/api/v2/consultants/jobs'
export const API_URL_CLIENT_INFO_FROM_TOKEN = (token: string): string => `/api/v2/consultants/clients/info?token=${token}`
export const API_URL_CLIENT_ACTIVE = `/api/v2/consultants/clients/active`

export const API_FETCH_LOCATION = '/api/v2/public/locations'
export const API_URL_PROFILE = '/api/v2/public/profile'
export const API_CANDICATE_PROFILE = '/api/v2/candidates/profile'
export const API_URL_APPLICANTS = '/api/v2/consultants/applicants'
export const API_URL_APPLICANTS_CONSULTANTS = '/api/v2/consultants/applicants'
export const API_URL_PATCH_PROFILES_SOURCE = (id) => `/api/v2/consultants/profiles/:id/update_data`.replace(':id', id)

export const API_URL_COMPANY_CREATE_CLIENT = 'api/v2/consultants/clients'
export const API_URL_LIST_ACCOUNT_MANAGERS = 'api/v2/consultants/account_managers'
export const API_URL_CONSULTANTS_COMPANIES = 'api/v2/consultants/companies'
export const API_URL_COMPANY_CREATE_CLIENT_ASSIGN = 'api/v2/consultants/clients/assign'

export const API_URL_CONSULTANT_CREATE_JOB_4_CLIENT = `/api/v2/consultants/jobs`

export const API_URL_CONSULTANT_GET_ACTIVE_JOBS = `/api/v2/consultants/jobs/actives`
export const API_URL_CONSULTANT_GET_SOURCE = `/api/v2/consultants/candidates`
export const API_URL_COMPANIES_MEMBERS = '/api/v2/consultants/members?active=true'
export const API_URL_CONSULTANT_ALL_MEMBER = '/api/v2/consultants/members'
export const API_URL_CONSULTANT_CLIENTS = '/api/v2/consultants/clients?fetch_id=true'
export const API_URL_CONSULTANT_GET_JOB_ORDERS = `/api/v2/consultants/job_orders`
export const API_URL_CONSULTANT_AMS = `/api/v2/consultants/members/member_ams`

export const API_URL_CONSULTANT_CANDIDATE_GET_EMAIL_LOGS = (candidateId: string | string[] | number) =>
  `/api/v2/consultants/candidates/${candidateId}/email_logs`

export const API_URL_CONSULTANT_CLIENT = `/api/v2/consultants/clients?status=1`
export const API_URL_JOBS_CANDIDATES = (jobId: number | string | string[]): string => {
  return `/api/v2/consultants/jobs/${jobId}/candidates`
}
export const API_URL_CONSULTANT_CREATE_PROFILE = `/api/v2/consultants/candidates/profile`

export const API_URL_COMPANY_JOB_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/consultants/jobs/${jobId}/stages`
}
export const API_URL_COMPANY_APPLICANTS_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/consultants/applicants/${jobId}/stages/update`
}

export const API_URL_CONSULTANT_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/consultants/applicants/${candidateId}/disqualify`

export const API_URL_CONSULTANT_APPLICANTS_DISQUALIFY_NEW = (applicantId: number | string): string =>
  `api/v2/consultants/applicants/${applicantId}/reprocess`

export const API_URL_COMPANY_CANDIDATE_EVALUATION = (applicantId: string): string =>
  `/api/v2/consultants/profiles/${applicantId}/evaluations`
export const API_URL_COMPANY_CANDIDATE_EVALUATION_GET = (applicantId: string | number, id: string): string =>
  `/api/v2/consultants/profiles/${applicantId}/evaluations/${id}`
export const API_URL_JOB_NOTES = (jobId: number | string | string[]): string => {
  return `/api/v2/consultants/jobs/${jobId}/notes`
}
export const API_URL_UPDATE_JOB_NOTES = (jobId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/consultants/jobs/${jobId}/notes/${id}`
}
export const API_URL_APPLICANTS_NOTES = (profile_id: number | string | string[]): string => {
  return `/api/v2/consultants/profiles/${profile_id}/notes`
}
export const API_URL_UPDATE_APPLICANTS_NOTES = (profile_id: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/consultants/profiles/${profile_id}/notes/${id}`
}
export const API_URL_COMPANY_JOBS = `/api/v2/consultants/jobs/simple_index?status_arr[]=1&status_arr[]=2&freec_status_arr[]=1&freec_status_arr[]=3`
export const API_URL_TALENT_POOL = `/api/v2/public/talent_pools`
export const API_URL_EDIT_TALENT_POOL = (candidateId: number | string | string[]): string => {
  return `api/v2/consultants/candidates/${candidateId}/talent-pool`
}
export const API_URL_FETCH_INFO_APPLICANTS = `/api/v2/consultants/applicants/info`
export const API_URL_SEND_CANDIDATES = `/api/v2/consultants/applicants/sent_to_clients`
export const API_URL_PROFILE_APPLIED_JOB = (profile_id: number | string | string[], { ignore }): string => {
  return `api/v2/consultants/profiles/${profile_id}/applied_jobs${ignore === true ? '?ignore_offer=true' : ''}`
}

export const API_URL_INTERVIEW_APPLICANT = (profile_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/interviews`
}
export const API_URL_UPDATE_INTERVIEW_TYPE = (profile_id: number | string, interview_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/interviews/${interview_id}/update_type`
}
export const API_URL_SHOW_AN_INTERVIEW = (profile_id: number | string | string[], interviewId: number | string | string[]): string => {
  return `/api/v2/consultants/profiles/${profile_id}/interviews/${interviewId}`
}
export const API_URL_GET_LIST_INTERVIEW = `/api/v2/consultants/interviews`
export const API_URL_GET_LIST_INTERVIEW_DASHBOARD = `/api/v2/consultants/interviews/dashboard`
export const API_URL_CANCEL_INTERVIEW = (profile_id: number | string, interview_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/interviews/${interview_id}/cancel`
}

export const API_URL_ACTIVITY_LIST = (id: string | number | string[]) => `/api/v2/consultants/profiles/${id}/activities`
export const API_URL_JOB_ACTIVITY_LIST = (id: string | number | string[]) => `/api/v2/consultants/job_orders/${id}/activities`
export const API_URL_CLIENT_ACTIVITY_LIST = (id: string | number | string[]) => `/api/v2/consultants/companies/${id}/activities`
export const API_URL_ACTIVITY_ACTION_TYPE_LIST = (id: string | number | string[]) =>
  `/api/v2/consultants/profiles/${id}/activities/action_types`
export const API_URL_REMOVE_MEMBER = (id_member: string | string[] | number) => `/api/v2/consultants/members/${id_member}/active`
export const API_URL_CHECK_VALIDATION_EMAIL = `/api/v2/consultants/candidates/check_email`
export const API_URL_CLIENT_NOTES = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes`
}
export const API_URL_UPDATE_CLIENT_NOTES = (clientId: number | string | string[], noted_id: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes/${noted_id}`
}

export const API_URL_JOBS_PROFILE = (profile_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/applicants?job_order=true`
}

export const API_URL_TALENT_POOLS = (profile_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/profile_talent_pools`
}

export const API_URL_TALENT_POOLS_DELETE = (profile_id: number | string, id: number): string => {
  return `/api/v2/consultants/profiles/${profile_id}/profile_talent_pools/${id}`
}

export const API_URL_TALENT_POOLS_JOB_CATEGORIES = (profile_id: number | string): string => {
  return `/api/v2/consultants/profiles/${profile_id}/job_categories`
}

export const API_URL_TALENT_POOLS_JOB_CATEGORIES_DELETE = (profile_id: number | string, id: number): string => {
  return `/api/v2/consultants/profiles/${profile_id}/job_categories/${id}`
}

export const API_URL_TALENT_POOLS_LIST = (profile_id?: number | string): string => {
  if (!profile_id) {
    return '/api/v2/consultants/talent_pools'
  }

  return `/api/v2/consultants/talent_pools?profile_id=${profile_id}`
}

export const API_URL_ADD_TALENT_POOLS_CONSULTANT_JOB = (job_id: number | string): string => {
  return `/api/v2/consultants/jobs/${job_id}/add_talent_pool`
}

export const API_URL_REMOVE_TALENT_POOLS_CONSULTANT_JOB = (job_id: number | string): string => {
  return `/api/v2/consultants/jobs/${job_id}/remove_talent_pool`
}

export const API_URL_LIST_TALENT_POOLS_CONSULTANT_JOB = (job_id: number | string, searchString: string): string => {
  return `/api/v2/consultants/jobs/${job_id}/talent_pool_profiles?${searchString}`
}

export const API_URL_ACTION_TALENT_POOLS_CONSULTANT_JOB = (profile_id: number | string): string => {
  return `/api/v2/consultants/candidates/${profile_id}/hide_from_job_talent_pool`
}

export const API_URL_ADD_CANDIDATE_TO_JOB_STAGE = (job_id: number | string): string => {
  return `/api/v2/consultants/jobs/${job_id}/candidates`
}

export const API_URL_ASSIGN_OWNERSHIP = (id: string | string[] | number) => `/api/v2/consultants/applicants/${id}/assign_ownership`

export const API_URL_CONSULTANT_OFFERS = (profile_id: number | string | string[], job_id: number | string | string[]) =>
  `/api/v2/consultants/profiles/${profile_id}/offers${job_id ? `?job_id=${job_id}` : ''}`
export const API_URL_CONSULTANT_CREATE_OFFER = `/api/v2/consultants/offers`
export const API_URL_CONSULTANT_APPLICANT_OFFER = (id: number | string | string[]) => `/api/v2/consultants/offers/${id}`
export const API_URL_EMAILTEMPLATE_DETAIL = (id: string | string[] | number) => `/api/v2/consultants/email_templates/${id}`

export const API_URL_OFFER_UPDATE_STATUS = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/consultants/offers/${id}/update_status`
export const API_URL_PLACEMENT_CREATE = '/api/v2/consultants/placements'
export const API_URL_PLACEMENT_UPDATE_STATUS = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/consultants/placements/${id}/update_status`
export const API_URL_PLACEMENT_UPDATE_SOURCE = (id) => `/api/v2/consultants/placements/${id}/update_source`
export const API_URL_PLACEMENT_LIST = `/api/v2/consultants/placements`
export const API_URL_PLACEMENTS = (profile_id: string | string[] | number, job_id: number | string | string[]) =>
  `/api/v2/consultants/profiles/${profile_id}/placements${job_id ? `?job_id=${job_id}` : ''}`
export const API_URL_PLACEMENT_UPDATE = (profile_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/consultants/placements/${id}`
export const API_URL_CONSULTANT_OFFERS_LIST = `/api/v2/consultants/offers`

export const API_URL_CONSULTANT_PLACEMENT = (client_id: string | number | string[]) => `/api/v2/consultants/clients/${client_id}/placements`
export const API_URL_CONSULTANT_DELETE_PLACEMENT = (placement_id: string | number) => `/api/v2/consultants/placements/${placement_id}`

export const API_CONSULTANT_COMPANY_DETAIL = (company_id: string | number | string[]) => `/api/v2/consultants/companies/${company_id}`
export const API_CONSULTANT_COMPANIES_JOB_POST = (id: string | string[] | number) => `/api/v2/consultants/companies/${id}/job_posts`
export const API_CONSULTANT_COMPANIES_JOB_ORDERS = (id: string | string[] | number) => `/api/v2/consultants/companies/${id}/job_orders`
export const API_CONSULTANT_UPDATE_COMPANY = (company_id: string | string[] | number) => `/api/v2/consultants/companies/${company_id}`

export const API_URL_LIST_TALENT_POOL = `/api/v2/consultants/talent_pools`
export const API_URL_LIST_JOB_CATEGORIES = `/api/v2/consultants/job_categories`
export const API_URL_LIST_JOB_CATEGORIES_MAIN_CATEGORIES = `/api/v2/consultants/job_categories/main_categories`

export const API_URL_TALENT_POOL_DETAIL = (id: string | string[] | number) => `/api/v2/consultants/talent_pools/${id}`
export const API_URL_JOB_CATEGORIES_DETAIL = (id: string | string[] | number) => `/api/v2/consultants/job_categories/${id}`

export const API_CONSULTANT_CONTACT_REQUEST = (company_id: string | string[] | number) =>
  `/api/v2/consultants/companies/${company_id}/contact_requests`

export const API_URL_RECRUITER_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/consultants/applicants/${candidateId}/disqualify`

export const API_COMPANIES_RECRUITERS = `/api/v2/consultants/recruiters`
export const API_COMPANIES_RECRUITERS_ASSIGN_JOB = (jobID: number | string): string =>
  `/api/v2/consultants/jobs/${jobID}/recruiters/assign_job`
export const API_COMPANIES_RECRUITERS_REMOVE_ASSIGN_JOB = (jobID: number | string, id: number | string): string =>
  `/api/v2/consultants/jobs/${jobID}/recruiters/${id}/remove_assigned_job`

export const API_COMPANIES_PROFILE = `/api/v2/consultants/profiles`
export const API_CONSULTANT_TASK = '/api/v2/consultants/tasks'
export const API_CONSULTANT_TASK_UPDATE = (id: string | string[] | number) => `/api/v2/consultants/tasks/${id}`
export const API_CONSULTANT_TASK_UPDATE_STATUS = (id: string | string[] | number) => `/api/v2/consultants/tasks/${id}/update_data`
export const API_CONSULTANT_PROFILE_TASK = (id: string | string[] | number) => `/api/v2/consultants/profiles/${id}/tasks`
export const API_CONSULTANT_DELETE_TASK = (id: string | string[] | number) => `/api/v2/consultants/tasks/${id}`

export const API_CONSULTANT_HIDE_CANDIDATE = `/api/v2/consultants/candidates/hide`
export const API_CONSULTANT_BULK_ACTION_ADD_TALENT_POOL = `/api/v2/consultants/candidates/bulk_add_talent_pool`
export const API_CONSULTANT_BULK_ACTION_ASSIGN_JOB = (job_id: string | string[] | number) =>
  `/api/v2/consultants/jobs/${job_id}/bulk_candidates`

export const API_URL_CONSULTANT_COMPANY_BLOCK = (companyId: number): string => `/api/v2/consultants/companies/${companyId}/block`
export const API_URL_CONSULTANT_CLIENT_UPLOAD_FILE = `/api/v2/consultants/client_files`
export const API_URL_CONSULTANT_CLIENT_GET_FILES = (clientId: string | string[] | number) => `/api/v2/consultants/clients/${clientId}/files`
export const API_URL_CONSULTANT_CLIENT_DELETE_FILE = (fileId: string | string[] | number) => `/api/v2/consultants/client_files/${fileId}`
export const API_URL_CONSULTANT_CLIENT_UPDATE_CONTRACT = (clientId: string | string[] | number) =>
  `/api/v2/consultants/clients/${clientId}/update_contract_status`

export const API_URL_CONSULTANT_CLIENT_GET_EMAIL_LOG = (clientId: string | string[] | number) =>
  `/api/v2/consultants/clients/${clientId}/email_logs`

export const API_URL_CONSULTANT_REPORT = `/api/v2/consultants/reports`
export const API_URL_CONSULTANT_REPORT_DASHBOARD = `api/v2/consultants/reports/list`
export const API_URL_CONSULTANT_OPEN_JOB = `/api/v2/consultants/job_orders`

export const API_URL_CONSULTANT_TALENT_POOL_REMOVE = (talentPoolId: string | string[] | number, profileId: string | number) =>
  `/api/v2/consultants/talent_pools/${talentPoolId}/profiles/${profileId}/delete`

export const API_URL_CONSULTANT_TALENT_POOL_LIST_DETAIL = (talentPoolId: string | string[] | number) =>
  `/api/v2/consultants/talent_pools/${talentPoolId}/profiles`

export const API_CONSULTANT_PROFILE_REPORT = `/api/v2/consultants/reports/consultants/profiles`
export const API_CONSULTANT_VERIFIED_PROFILE_REPORT = `/api/v2/consultants/reports/consultants/verified_profiles`
export const API_CONSULTANT_APPLICANTS_MATCHING_REPORT = `/api/v2/consultants/reports/consultants/applicants_matching`
export const API_CONSULTANT_APPLICANTS_SOURCED_REPORT = `/api/v2/consultants/reports/consultants/applicants_sourced`
export const API_CONSULTANT_JOB_ORDERS_REPORT = `/api/v2/consultants/reports/consultants/job_orders`
export const API_CONSULTANT_SENT_TO_AM_REPORT = `/api/v2/consultants/reports/consultants/sent_to_ams`
export const API_CONSULTANT_SENT_TO_CLIENT_REPORT = `/api/v2/consultants/reports/consultants/sent_to_clients`
export const API_CONSULTANT_INTERVIEW_REPORT = `/api/v2/consultants/reports/consultants/interviews`
export const API_CONSULTANT_COMPLETED_INTERVIEW_REPORT = `/api/v2/consultants/reports/consultants/completed_interviews`
export const API_CONSULTANT_FINAL_INTERVIEW_REPORT = `/api/v2/consultants/reports/consultants/final_interviews`
export const API_CONSULTANT_OFFER_REPORT = `/api/v2/consultants/reports/consultants/offers`
export const API_CONSULTANT_PLACEMENT_REPORT = `/api/v2/consultants/reports/consultants/placements`
export const API_CONSULTANT_PAID_PLACEMENT_REPORT = `/api/v2/consultants/reports/consultants/paid_placements`
export const API_CONSULTANT_CLIENT_REPORT = `/api/v2/consultants/reports/consultants/clients`
export const API_CONSULTANT_CLIENT_NOTE_REPORT = `/api/v2/consultants/reports/consultants/client_notes`
export const API_CONSULTANT_JOB_NOTE_REPORT = `/api/v2/consultants/reports/consultants/job_notes`
export const API_CONSULTANT_CANDIDATE_NOTE_REPORT = `/api/v2/consultants/reports/consultants/candidate_notes`
export const API_CONSULTANT_ACTIVITY_REPORT = `/api/v2/consultants/reports/consultants/activities`
export const API_CONSULTANT_ROLE_INVITE_MEMBER = `/api/v2/consultants/roles`
export const API_CONSULTANT_UPDATE_ROLE_MEMBER = (id: string | string[] | number) => `/api/v2/consultants/members/${id}/change_role`
export const API_CONSULTANT_GET_ALL_COMPANY_DIRECTORIES = '/api/v2/consultants/company_directories'
export const API_CONSULTANT_GET_SEARCH_COMPANY_DIRECTORIES = '/api/v2/consultants/company_directories/search'
export const API_CONSULTANT_COMPANY_DIRECTORIES_ID = (id: string | string[] | number) => `/api/v2/consultants/company_directories/${id}`
export const API_CONSULTANT_COMPANY_DIRECTORIES_ID_STATUS = (id: string | string[] | number) =>
  `/api/v2/consultants/company_directories/${id}/change_status`
export const API_CONSULTANT_COMPANY_DIRECTORIES_ID_VERIFY = (id: string | string[] | number) =>
  `/api/v2/consultants/company_directories/${id}/verify`
export const API_CONSULTANT_COMPANY_DIRECTORIES_MERGE = `/api/v2/consultants/company_directories/merge`
export const API_CONSULTANT_COMPANY_DIRECTORIES_CHANGE_STATUS = `/api/v2/consultants/company_directories/verify`

export const API_COMPANY_BRANCH_ADDRESSES = 'api/v2/companies/company_branch_addresses'
export const API_CONSULTANT_UPDATE_INVITE_MEMBER = (id: string | string[] | number) => `/api/v2/consultants/members/${id}/update_data`
export const API_CONSULTANT_UPDATE_TEAM_MEMBER = `/api/v2/consultants/teams/change_team`
export const API_CONSULTANT_USER_STATUS = (id: string | string[] | number) => `/api/v2/consultants/accounts/user_status?user_id=${id}`
export const API_CONSULTANT_MATCH_PROFILE = (profile_id: string | string[] | number, job_id: string | string[] | number) =>
  `/api/v2/consultants/applicants/${profile_id}/applicant_mathching?job_id=${job_id}`
export const API_CONSULTANT_MATCH_MULTIPLE_PROFILE = (profile_id: string | string[] | number) =>
  `/api/v2/consultants/applicants/${profile_id}/applicant_mathching?mutiple_applicant_mathching=true`
export const API_CONSULTANT_COUNT_APPLICANT = `/api/v2/consultants/applicants/count_applicants_by_stage`
export const API_CONSULTANT_COUNT_BY_STAGE_INDEX = `/api/v2/consultants/applicants/count_applicants_by_stage_index`
export const API_CONSULTANT_SEARCH_PROFILE = `/api/v2/consultants/profiles/meilisearch`
export const API_CONSULTANT_SEARCH_JOB = `/api/v2/consultants/jobs/searching`
export const API_CONSULTANT_SEARCH_COMPANY = `/api/v2/consultants/companies/search`
export const API_FREEZE_JOB = (job_id: string | string[] | number) => `/api/v2/consultants/job_orders/${job_id}/set_freezing_job`
export const API_SEARCH_REPLACEMENT = `api/v2/consultants/placements/search_replacement_for`
